import React, { useEffect } from 'react'
import "./style.scss";
import { Box, ButtonBase } from '@mui/material';
import confetti from 'canvas-confetti';

export default function Thanks() {
    const gotoHome = () => {
        window.open("https://cruiz.ai", "_self");
    }

    useEffect(() => {
        confetti({
            particleCount: 100,
            spread: 100,
            origin: { y: 0.7 }
        });
    }, [])

    return (
        <Box className="thanks-box">
            <div className='container'>
                <img src="/fav.png" alt="logo" />
                <div className='title'>
                    Thank you for signing up to CRUIZ - The Future of Rideshare.
                </div>
                <div className='form'>
                    <ButtonBase className='btn-signup' onClick={gotoHome}>Go to Home</ButtonBase>
                </div>
            </div>
        </Box >
    )
}