import React, { useContext, useEffect, useState } from 'react'
import "./style.scss";
import { Box, ButtonBase, Container } from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

export default function Home() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(0);
  const { invite } = useParams();
  const navigate = useNavigate();
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { latitude: "", longitude: "" },
  });

  const registerUser = async (_name, _email, _role, _latitude, _longitude) => {
    const url = 'https://api.cruiz.ai/api/user/add';
    const data = {
      name: _name,
      email: _email,
      role: _role,
      latitude: _latitude,
      longitude: _longitude,
      invite: invite
    };

    await toast.promise(
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => {
        console.log("response: ", response);
        if (response.ok) {
          navigate('/thanks');
          return;
        } else if (response.status === 409) {
          throw new Error('This email is already registered');
        } else {
          throw new Error('Failed to register user');
        }
      }),
      {
        loading: 'Registering...',
        success: 'Registered successfully!',
        error: (e) => e.message
      }
    );
  };

  const onSignUp = (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Name is required");
      return;
    }

    if (!email) {
      toast.error("Email address is invalid");
      return;
    }

    if (email === invite) {
      toast.error("The invite link is invalid");
      return;
    }

    if (!location.loaded) {
      toast.error("Your browser doesn't support geo location.");
      return;
    }

    if (location.error) {
      toast.error("Location required. Your browsers location services are currently turned off");
      return;
    }

    registerUser(name, email, role, location.coordinates.latitude, location.coordinates.longitude);

  }

  const onSuccess = location => {
    setLocation({
      loaded: true,
      coordinates: {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      },
    });
  };

  const onError = error => {
    setLocation({
      loaded: true,
      error,
    });
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(function () {
      toast.success('Invite link copied');
    }, function (err) {
      toast.error('Failed to generate invite');
    });
  }

  const onCopyInvite = () => {
    if (!email) {
      toast.error("Please input the email first");
      return;
    }

    copyTextToClipboard(`https://signup.cruiz.ai/invite/${email}`);
  }

  return (
    <Box className="home-box">
      <form className='container' onSubmit={onSignUp}>
        <img src="/fav.png" alt="logo" />
        <div className='title'>
          Cruiz an AI driven rideshare that utilizes DePIN technology
        </div>
        <div className='form'>
          <input placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
          <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
          <div className='options'>
            <ButtonBase className={`option ${role === 0 ? "active" : ""}`} onClick={() => { setRole(0) }}>Driver</ButtonBase>
            <ButtonBase className={`option ${role === 1 ? "active" : ""}`} onClick={() => { setRole(1) }}>Cruiz'r</ButtonBase>
          </div>
          <ButtonBase type="submit" className='btn-signup'>Sign up</ButtonBase>
          <ButtonBase onClick={onCopyInvite} className='btn-signup'>Copy Invite Link</ButtonBase>
          <a href='https://explorer.cruiz.ai' className='btn-signup' target='_blank'>
            See Explorer
          </a>
        </div>
      </form>
    </Box >
  )
}