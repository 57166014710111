import React from 'react';
import * as ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './index.css';
import "./star.scss";
import "./global.scss";
import Home from "./pages/home";
import { Toaster } from 'react-hot-toast';
import Thanks from './pages/thanks';

function AppProvider({ children }) {
  return (
    <div>
      <div className="particle particle-1"></div>
      <div className="particle particle-2"></div>
      <div className="particle particle-3"></div>
      <div className="particle particle-4"></div>
      {children}
      <Toaster />
    </div>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/invite/:invite" element={<Home />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </AppProvider>
  </React.StrictMode>
);

reportWebVitals();
